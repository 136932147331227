import React, { Fragment, useState } from "react"
import { useTimeoutFn } from "react-use"
import { Transition } from "@headlessui/react"
import SectionTitle from "../../../components/title"
import Button from "../../../components/button"

export const TypeTournament = "tournament"

export const TypeConnectivity = "connectivity"
export const TypeLag = "lag"
export const TypeGraphics = "graphics"
export const TypeTrading = "trading"
const types = [
    TypeTournament,
    TypeConnectivity,
    TypeLag,
    TypeGraphics,
    TypeTrading
]

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

const Ping = ({ color }) => {
    const primaryColor = `${color ?? "bg-green"}`

    return (
        <span className="flex h-20 w-20 relative">
            <span
                className={`animate-ping absolute inline-flex h-full w-full rounded-full ${primaryColor} opacity-75`}
            ></span>
            <span
                className={`relative inline-flex rounded-full h-20 w-20 ${primaryColor}`}
            ></span>
        </span>
    )
}

const CurrentStatus = ({ bubbleColor }) => {
    return (
        <div className={"flex justify-center items-center"}>
            <div className={"mr-4"}>
                <Ping color={bubbleColor} />
            </div>
            <div>There are no active issue reports.</div>
        </div>
    )
}

const GetDescription = () => {
    let [isReportButtonShowing, setIsReportButtonShowing] = useState(true)
    let [bubbleColor, setBubbleColor] = useState("bg-green")
    let [isReportTypeButtonsShowing, setIsReportTypeButtonsShowing] =
        useState(false)

    const show = () => {
        setTimeout(() => {
            setBubbleColor("bg-green")
            setIsReportTypeButtonsShowing(true)
        }, 300)
    }

    const GetReportTypeButtons = () => {
        return types.map((type) => {
            return (
                <Button
                    key={type}
                    onClick={() => {
                        setIsReportTypeButtonsShowing(false)
                        setTimeout(() => {
                            setBubbleColor("bg-punch")
                            setIsReportButtonShowing(true)
                        }, 300)
                    }}
                >
                    {capitalize(type)}
                </Button>
            )
        })
    }

    return (
        <div className={"relative"}>
            <div className={"h-12"}>
                <Transition
                    as={Fragment}
                    appear={true}
                    show={isReportButtonShowing}
                    enter="transform transition duration-[400ms]"
                    enterFrom="opacity-0 rotate-[-120deg] scale-50"
                    enterTo="opacity-100 rotate-0 scale-100"
                    leave="transform duration-200 transition ease-in-out"
                    leaveFrom="opacity-100 rotate-0 scale-100 "
                    leaveTo="opacity-0 scale-95 "
                >
                    <div>
                        <Button
                            onClick={() => {
                                console.log("on click")
                                setIsReportButtonShowing(false)
                                show()
                            }}
                        >
                            Report a problem
                        </Button>
                    </div>
                </Transition>
                <Transition
                    as={Fragment}
                    appear={true}
                    show={isReportTypeButtonsShowing}
                    enter="transform transition duration-[400ms]"
                    enterFrom="opacity-0 rotate-[-120deg] scale-50"
                    enterTo="opacity-100 rotate-0 scale-100"
                    leave="transform duration-200 transition ease-in-out"
                    leaveFrom="opacity-100 rotate-0 scale-100 "
                    leaveTo="opacity-0 scale-95 "
                >
                    <div className={"flex gap-2"}>
                        <GetReportTypeButtons />
                    </div>
                </Transition>
            </div>
            <div className={"mt-14"}>
                <CurrentStatus bubbleColor={bubbleColor} />
            </div>
        </div>
    )
}

const Hero = () => {
    return (
        <section className="pt-16 md:pt-24">
            <div className="container px-4 my-32">
                <SectionTitle
                    heading={
                        <>
                            <span>Experiencing connectivity issues?</span>
                        </>
                    }
                    description={<GetDescription />}
                    align={"center"}
                    showDescription={true}
                />
            </div>
        </section>
    )
}

export default Hero
