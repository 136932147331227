import React, { useEffect, useState } from "react"
import PlayIcon from "../../assets/images/svg/icons/play.svg"
import EyeIcon from "../../assets/images/svg/icons/eye.svg"
import { getTwitchStreams } from "../../api"
import Button from "../button"

const Stream = ({ stream, visible }) => {
    return (
        <div
            className={`relative transition-all transform ${
                !visible ? "scale-y-100" : "hidden scale-y-0"
            }`}
        >
            <div className="player-thumb relative group">
                <a href={stream.stream_url} target={"_blank"} rel="noreferrer">
                    <img
                        className="w-full rounded-md z-10"
                        src={stream.image}
                        alt=""
                    />
                    <span className="w-full h-full absolute left-0 top-0 bg-gray-900 opacity-0 group-hover:opacity-70"></span>
                    <div
                        className={
                            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        }
                    >
                        <PlayIcon className={"h-14 w-14 text-white"} />
                    </div>
                    <div
                        className={
                            "absolute bottom-2 right-2 px-2 flex flex-row items-center rounded bg-purple-500 text-sm"
                        }
                    >
                        <span className={"mr-2"}>
                            <EyeIcon className={"h-4 w-4 text-white"} />
                        </span>{" "}
                        {stream.viewers}
                    </div>
                </a>
            </div>

            <h5 className={"ml-1 mt-2 text-shadow"}>
                <a href={stream.stream_url} target={"_blank"} rel="noreferrer">
                    {stream.name}
                </a>
            </h5>
        </div>
    )
}

const TwitchStreams = () => {
    const [streams, setStreams] = useState([])
    const [viewMore, setViewMore] = useState(false)

    useEffect(() => {
        async function fetchStreams() {
            let list = await getTwitchStreams()
            setStreams(list)
        }

        fetchStreams()
    }, [])

    const limitedStreams = streams
    // if (viewMore) {
    //     limitedStreams.push(...streams.slice(8, streams.length))
    // }

    return (
        <section className="player-section mb-15 md:pb-80">
            <div className="container transition-all">
                <div className={"relative"}>
                    <span className="absolute w-2/6 h-0.5 right-0 -top-0 xs:block sm:hidden md:block bg-primary"></span>
                </div>
                <div className={"text-md mb-4"}>Active RL streamers</div>
                <div className="grid gap-y-10 gap-4 lg:gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    {limitedStreams.map(function (stream, index) {
                        return (
                            <Stream
                                key={stream.name}
                                visible={!viewMore && index > 7}
                                stream={stream}
                            />
                        )
                    })}
                </div>
                {!viewMore ? (
                    <div
                        className={"flex flex-row items-center justify-center"}
                    >
                        <Button onClick={() => setViewMore(true)}>
                            View more
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </section>
    )
}

export default TwitchStreams
