import React from "react"

const Button = ({ children, onClick }) => (
    <button
        onClick={onClick}
        className={
            "mt-4 inline-block hover:text-white hover:border-white text-purple-500 border-purple-500 border rounded-md border-white px-4 py-2"
        }
    >
        {children}
    </button>
)

export default Button
