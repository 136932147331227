import axios from "axios"

const port = 1112
let apiAddress = ""
if (typeof window !== "undefined") {
    apiAddress = window.location.hostname
    if (apiAddress.includes("127.0.0.1") || apiAddress.includes("127.0.0.1")) {
        apiAddress = `http://127.0.0.1:${port}`
    } else {
        // production
        apiAddress = `https://api.rocketleaguestatus.com`
    }
}

const api = axios.create({
    baseURL: apiAddress
    // timeout: 1000,
})

export async function getTwitchStreams() {
    try {
        const response = await api.get("/twitch")
        return response.data
    } catch (error) {
        console.error(error)
    }

    return []
}

export async function submitReport(type) {
    try {
        const response = await api.post("/reports", { type: type })
        return response.data
    } catch (error) {
        console.error(error)
    }

    return []
}

export async function getReports() {
    try {
        const response = await api.get("/reports")
        return response.data
    } catch (error) {
        console.error(error)
    }

    return []
}
