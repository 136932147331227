import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import SEO from "@components/seo"
import Layout from "@layout"
import Hero from "../container/home/hero"
import { graphql } from "gatsby"
import { normalizedData } from "@utils/functions"
import TwitchStreams from "../components/twitch-streams"

const IndexPage = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || [])

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"]
            }}
        >
            <SEO title="Home" pathname="/" />
            <Hero />
            <TwitchStreams />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        allMatch: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        allGames: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        allPlayers: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        })
    })
}

export const query = graphql`
    query homePageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
    }
`

export default IndexPage
